import { Box, Flex, useTheme } from '@chakra-ui/react';

import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import Link from '@/components/shared/Link';
import React from 'react';
import Text from '@/components/shared/Text';

type Props = {
  buttonOnClick: () => void;
  buttonText: string;
  linkIsExternal?: boolean;
  linkText: string;
  linkUrl: string;
  message: string;
};

const HeaderBanner: React.FC<Props> = ({
  buttonText,
  buttonOnClick,
  linkIsExternal = false,
  linkText,
  linkUrl,
  message,
}: Props) => {
  const theme = useTheme();

  return (
    <Flex backgroundColor="black" width="100%" justifyContent="center">
      <Box maxW={theme.maxPageWidth} width="100%" px={{ base: 4, md: 6, xl: 8 }}>
        <Flex alignItems="center" justifyContent="space-between" my="2">
          <Flex alignItems="center">
            <Text color="white" fontWeight="600" fontSize="sm">
              {message}
            </Text>
            <Link isExternal={linkIsExternal} fontSize="sm" fontWeight="600" href={linkUrl} ml="4" mt="1px">
              {linkText}
            </Link>
          </Flex>
          <Button
            appButtonType={ButtonType.Secondary}
            backgroundColor="white"
            onClick={buttonOnClick}
            fontSize="sm"
            height="6"
            variant="solid"
          >
            {buttonText}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
export default HeaderBanner;
