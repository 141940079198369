import { COZY_SITE_URL, MIGRATION_DOCS_URL } from '@/constants';

import HeaderBanner from './banners/HeaderBanner';
import React from 'react';

const MigrationBanner: React.FC = () => {
  const buttonOnClick = () => {
    window.open(COZY_SITE_URL);
  };

  return (
    <HeaderBanner
      buttonText="Back to Cozy App"
      buttonOnClick={buttonOnClick}
      linkIsExternal={true}
      linkText="Learn More"
      linkUrl={MIGRATION_DOCS_URL}
      message="You are on a deprecated version of Cozy. Withdraw your funds and move them to the live Cozy app."
    />
  );
};
export default MigrationBanner;
